import { media, styled } from '@styles';
import {
  blueLight,
  blueLightOpacitySemi,
  redLight,
  red,
  white,
  black,
  graySemi,
} from '@styles/constants';
import { Container as DefaultContainer } from '@components/templates/Container';
import { prismicRichText } from '@styles/utils';

const backgroundColors = {
  grey: 'hsl(210, 12%, 97%)',
  white,
  redLight,
  blueLight: blueLightOpacitySemi,
};

const containerColors = {
  grey: redLight,
  white: redLight,
  redLight: blueLight,
  blueLight: redLight,
};

const titleColors = {
  grey: red,
  white: red,
  redLight: white,
  blueLight: red,
};

const textColors = {
  grey: graySemi,
  white: graySemi,
  redLight: black,
  blueLight: graySemi,
};

const linkColors = {
  grey: blueLight,
  white: blueLight,
  redLight: red,
  blueLight,
};

export const Wrapper = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => backgroundColors[bgColor]};
  padding: 2em 2em;
`;

export const Container = styled(DefaultContainer)<{
  reverse: boolean;
  bgColor: string;
}>`
  background: ${({ bgColor }) => containerColors[bgColor]};
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row' : 'row-reverse')};
  justify-content: space-between;
  align-items: center;
  ${media.smallScreen`
    flex-direction: column;
  `}
  border-radius: 1rem;
  overflow: hidden;
`;

export const Paragraph = styled.div<{
  reverse: boolean;
  width: string | number;
}>`
  padding: 2em 2em;
  width: ${({ width }) => `calc(100% - ${width}%)`};
  ${media.smallScreen`
    width: 100%;
  `}
`;

export const Content = styled.div<{ bgColor: string }>`
  ${prismicRichText}
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ bgColor }) => titleColors[bgColor]};
  }

  p {
    color: ${({ bgColor }) => textColors[bgColor]};
  }

  a {
    color: ${({ bgColor }) => linkColors[bgColor]};
  }

  strong {
    color: ${({ bgColor }) => titleColors[bgColor]};
  }

  & em {
    color: ${({ bgColor }) => titleColors[bgColor]};
  }

  ul,
  ol {
    li {
      color: ${(props) => props.theme.colors.graySemi}; // #666f73;
    }
  }
`;

export const Photo = styled.div<{ reverse: boolean; width: string | number }>`
  width: ${({ width }) => `calc(${width}%)`};
  ${media.smallScreen`
    width: 100%;
  `}
`;
