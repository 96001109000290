import { ReactElement } from 'react';

import Link from 'next-translate-routes/link';

import { PrismicRichText } from '@prismicio/react';

import { PrismicNextImage } from '@prismic/components/PrismicNextImage';
import { linkResolver } from '@prismic/prismic-config';

import { Button } from '@components/atoms/Button';

import { generateKey } from '@utils/core';

import { IProps } from './types';
import * as S from './styles';

export function SliceImageCta({
  slice: { label, primary },
}: IProps): ReactElement {
  const reverse = label ? label.includes('-_') : true;
  const width = label ? label.slice(0, 2) : 50;
  const bgColor = primary?.bgcolor || 'redLight';

  return primary ? (
    <S.Wrapper className="slice-item slice-image-cta" bgColor={bgColor}>
      <S.Container reverse={reverse} bgColor={bgColor}>
        <S.Photo
          key={generateKey(
            primary?.content_text.slice(0, 9),
            'text-photo__photo'
          )}
          reverse={reverse}
          width={width}
        >
          {primary?.content_photo && (
            <PrismicNextImage
              field={primary?.content_photo}
              style={{ maxWidth: '100%', height: '100%', marginBottom: '-5px' }}
            />
          )}
        </S.Photo>
        <S.Paragraph
          key={generateKey(
            primary?.content_text.slice(0, 9),
            'text-photo__paragraph'
          )}
          reverse={reverse}
          width={width}
        >
          {primary?.content_text && (
            <S.Content bgColor={bgColor}>
              <PrismicRichText field={primary?.content_text} />
            </S.Content>
          )}
          {primary?.button_link && (
            <Link href={linkResolver(primary?.button_link)} passHref>
              <Button
                appearance={bgColor === 'redLight' ? 'filled' : 'outline'}
              >
                <PrismicRichText field={primary?.button_label} />
              </Button>
            </Link>
          )}
        </S.Paragraph>
      </S.Container>
    </S.Wrapper>
  ) : null;
}

SliceImageCta.componentName = 'slice-image-cta';
