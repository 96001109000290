import { ReactElement } from 'react';

import { PrismicRichText } from '@prismicio/react';

import Link from 'next-translate-routes/link';

import { ButtonLink } from '@components/atoms/Button';

import { linkResolver } from '@prismic/prismic-config';

import { IProps } from './types';
import * as S from './styles';

export function SliceLinkButton({ slice: { primary } }: IProps): ReactElement {
  const { button_link, button_label } = primary;

  return (
    <S.Wrapper className="slice-item slice-link">
      {button_link && (
        <Link href={linkResolver(button_link)} passHref>
          <ButtonLink className="slice-link-button">
            <PrismicRichText field={button_label} />
          </ButtonLink>
        </Link>
      )}
    </S.Wrapper>
  );
}

SliceLinkButton.componentName = 'linkbutton';
