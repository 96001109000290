import { ReactElement } from 'react';

import { PrismicRichText } from '@prismicio/react';

import { PrismicNextImage } from '@prismic/components/PrismicNextImage';

import Link from 'next-translate-routes/link';
import { Button } from '@components/atoms/Button';
import { linkResolver } from '@prismic/prismic-config';

import { IProps } from './types';
import * as S from './styles';

export function SliceImageCards({
  slice: { label, primary, fields },
}: IProps): ReactElement {
  const reverse = label ? label.includes('above-image') : false;

  return (
    <S.Wrapper className="slice-item slice-image-card">
      <S.Header>
        {primary?.header_title && (
          <S.HeaderTitle>
            <PrismicRichText field={primary.header_title} />
          </S.HeaderTitle>
        )}
      </S.Header>
      <S.Container columns={fields?.length || 0}>
        {fields.map((item, i) => (
          <S.Card key={`slice-image-card_${i}`}>
            <S.CardHeader reverse={reverse} centered={item?.card_centered}>
              {item?.card_image &&
                (item?.card_btn_link ? (
                  <Link href={linkResolver(item.card_btn_link)}>
                    <a>
                      <S.CardImage>
                        <PrismicNextImage
                          field={item.card_image}
                          style={{ maxWidth: '100%', height: '100%' }}
                        />
                      </S.CardImage>
                    </a>
                  </Link>
                ) : (
                  <S.CardImage>
                    <PrismicNextImage
                      field={item.card_image}
                      style={{ maxWidth: '100%', height: '100%' }}
                    />
                  </S.CardImage>
                ))}
              {item?.card_title && (
                <S.CardTitle>
                  <PrismicRichText field={item.card_title} />
                </S.CardTitle>
              )}
            </S.CardHeader>
            <S.CardWrapper centered={item?.card_centered}>
              {item?.card_content && (
                <S.CardContent>
                  <PrismicRichText field={item.card_content} />
                </S.CardContent>
              )}
              {item?.card_btn_label && item?.card_btn_link && (
                <S.CardLink>
                  <Link href={linkResolver(item.card_btn_link)}>
                    <a>
                      <Button size="sm" appearance="outline">
                        <PrismicRichText field={item.card_btn_label} />
                      </Button>
                    </a>
                  </Link>
                </S.CardLink>
              )}
            </S.CardWrapper>
          </S.Card>
        ))}
      </S.Container>
    </S.Wrapper>
  );
}

SliceImageCards.componentName = 'imagecard';
