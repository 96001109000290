import { media, styled } from '@styles';

export const Wrapper = styled.div`
  padding: 21px 42px;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  min-width: 380px;
  display: flex;
  justify-content: center;
  font-family: ${(props) => props.theme.typography.baseFontFamily};
  font-weight: 700;
  ${media.largeScreen`
    padding: 12px 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    min-width: 260px;
  `}
  ${media.smallScreen`
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 272px;
  `}
`;
