import { media, styled } from '@styles';
import { Container as DefaultContainer } from '@components/templates/Container';
import { pageTitle } from '@styles/utils';

export const Wrapper = styled(DefaultContainer)`
  margin: 0 auto;
  padding: 2rem 2rem;
`;

export const Container = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  ${media.smallScreen`
    grid-template-columns: repeat(1, 1fr);
  `}
`;

export const Header = styled.div`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.smallScreen`
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  `}
`;

export const HeaderTitle = styled.h2`
  ${pageTitle}
`;

export const Card = styled.div`
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
`;

export const CardHeader = styled.div<{ reverse: boolean; centered: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'column-reverse' : 'column')};
  align-items: ${({ centered }) => (centered ? 'center' : 'flex-start')};
`;

export const CardImage = styled.div`
  width: 100%;
`;

export const CardTitle = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  p {
    color: ${(props) => props.theme.colors.graySemi};
  }

  b {
    font-weight: 600;
  }

  strong {
    color: ${(props) => props.theme.colors.red};
  }

  i {
    font-style: italic;
  }

  & em {
    color: ${(props) => props.theme.colors.red};
  }
`;

export const CardContent = styled.div`
  p {
    font-size: ${(props) => props.theme.typography.baseFontSize};
    font-family: ${(props) => props.theme.typography.baseFontFamily};
    color: ${(props) => props.theme.colors.graySemi};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  b {
    font-weight: 600;
  }

  strong {
    color: ${(props) => props.theme.colors.red};
  }

  i {
    font-style: italic;
  }

  & em {
    color: ${(props) => props.theme.colors.red};
  }
`;

export const CardLink = styled.div``;

export const CardWrapper = styled.div<{ centered: boolean }>`
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;
